.flashcard {
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
}

.flashcard.no-transition {
    transition: none;
}

.flashcard.flipped {
    transform: rotateX(180deg);
}

.flashcard-front,
.flashcard-back {
    position: absolute;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.flashcard-back {
    transform: rotateX(180deg);
}

.volume-button.hidden {
    display: none;
}